import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import { ColumnSection, Column } from '../column-section';
import { Languages } from '../../tools/languages';
import SimpleSection from '../simple-section';
import Link from '../link';

import './index.scss';

const SolutionList = ({ categorySlug, solutions }) => (
  <ul>
    {solutions &&
      solutions.map(function (solution, index) {
        return (
          <li key={index}>
            <Link to={`/solutions/${categorySlug}/${solution.slug}/`}>
              <FormattedMessage id={solution.slug} />
            </Link>
          </li>
        );
      })}
  </ul>
);

const OurSolutions = ({ language }) => {
  const data = useStaticQuery(graphql`
    {
      integratedSecurity: allStrapiSolutionCategories(
        filter: { slug: { eq: "integrated-security" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
      perimeterSecurity: allStrapiSolutionCategories(
        filter: { slug: { eq: "perimeter-security" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
      scanAndDetection: allStrapiSolutionCategories(
        filter: { slug: { eq: "scan-and-detection" } }
      ) {
        nodes {
          solutions {
            slug
          }
          categorySlug: slug
        }
      }
    }
  `);

  const { integratedSecurity, perimeterSecurity, scanAndDetection } = data;

  return (
    <>
      <SimpleSection>
        <h2>{language === Languages.NL ? 'Onze oplossingen' : 'Our solutions'}</h2>
        <br />
      </SimpleSection>
      <ColumnSection indicator={true}>
        <Column isServiceColumn>
          <h3>
            <FormattedMessage id={integratedSecurity.nodes[0].categorySlug} />
          </h3>
          {integratedSecurity.nodes.length > 0 && (
            <SolutionList
              categorySlug={integratedSecurity.nodes[0].categorySlug}
              solutions={integratedSecurity.nodes[0].solutions}
            />
          )}
        </Column>
        <Column isServiceColumn>
          <h3>
            <FormattedMessage id={scanAndDetection.nodes[0].categorySlug} />
          </h3>
          {scanAndDetection.nodes.length > 0 && (
            <SolutionList
              categorySlug={scanAndDetection.nodes[0].categorySlug}
              solutions={scanAndDetection.nodes[0].solutions}
            />
          )}
        </Column>
        <Column isServiceColumn>
          <h3>
            <FormattedMessage id={perimeterSecurity.nodes[0].categorySlug} />
          </h3>
          {perimeterSecurity.nodes.length > 0 && (
            <SolutionList
              categorySlug={perimeterSecurity.nodes[0].categorySlug}
              solutions={perimeterSecurity.nodes[0].solutions}
            />
          )}
        </Column>
      </ColumnSection>
    </>
  );
};

OurSolutions.propTypes = {
  language: PropTypes.string,
};

OurSolutions.defaultProps = {
  language: Languages.EN,
};

export default OurSolutions;
