import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import { PrimaryLink } from '../buttons';
import { graphql, StaticQuery } from 'gatsby';
import { Languages } from '../../tools/languages';
import PlaceholderImage from '../../../static/images/placeholder.jpg';

import Img from 'gatsby-image';

import './index.scss';

const query = graphql`
  query RecentNewsQuery {
    image: allStrapiNewsItems(limit: 1, sort: { fields: [published], order: [DESC] }) {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    newsItems: allStrapiNewsItems(limit: 5, sort: { fields: [published], order: [DESC] }) {
      dutch: edges {
        node {
          id
          slug
          title: title_nl
          published(formatString: "D MMMM YYYY", locale: "NL")
        }
      }
      english: edges {
        node {
          id
          slug
          title: title_en
          published(formatString: "D MMMM YYYY", locale: "EN")
        }
      }
    }
  }
`;

const News = ({ linkTitle, headerImage, newsItems, className, ...props }) => {
  const firstNewsItem = newsItems[0];

  headerImage = headerImage ? headerImage : PlaceholderImage;

  return (
    <section className={'news-section' + (className ? ' ' + className : '')}>
      <Img className="image" fluid={headerImage} />
      <div className="news-title">
        <h3>{props.title}</h3>
        <PrimaryLink to="/news/">{linkTitle}</PrimaryLink>
      </div>

      <nav className="other-articles">
        {newsItems.slice(1).map((item) => {
          const { id, slug, title, published } = item.node;
          return (
            <Link key={id} to={`/news/${slug}/`}>
              <h4>{title}</h4>
              <time dateTime={published}>{published}</time>
            </Link>
          );
        })}
      </nav>

      <Link to={`/news/${firstNewsItem.node.slug}/`}>
        <h4>{firstNewsItem.node.title}</h4>
        <time dateTime={firstNewsItem.node.published}>{firstNewsItem.node.published}</time>
      </Link>
    </section>
  );
};

const NewsSection = ({ language, title, className, ...props }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <News
          title={language === Languages.NL ? 'Nieuws' : 'News'}
          linkTitle={language === Languages.NL ? 'Zie alles' : 'View all'}
          headerImage={
            data.image.edges.length > 0
              ? data.image.edges[0].node?.image?.childImageSharp?.fluid
              : 'https://via.placeholder.com/640?text=Bavak'
          }
          newsItems={language === Languages.NL ? data.newsItems.dutch : data.newsItems.english}
          className={className}
          props={props}
        />
      )}
    />
  );
};

NewsSection.propTypes = {
  language: PropTypes.string,
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
};

NewsSection.defaultProps = {
  language: Languages.EN,
  title: 'News',
};

export default NewsSection;
