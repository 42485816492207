import React from 'react';

import './index.scss';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import Img from 'gatsby-image';

const ImageSlide = ({ fluidImage, alt }) => (
  <div className="keen-slider__slide slide">
    <Img
      className="image"
      imgStyle={{
        transition: 'opacity 500ms ease 0s, transform 0.8s ease',
      }}
      fluid={fluidImage}
      alt={alt}
    />
  </div>
);

const HomeHeroSlider = ({ images }) => {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
    },
    [
      (slider) => {
        let timeout;

        function nextTimeout() {
          clearTimeout(timeout);

          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }

        slider.on('created', () => nextTimeout());
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    <div className="slider-container">
      <div ref={sliderRef} className="keen-slider slider">
        {images.map((image, index) => {
          const fluidImage = image.formats.large.childImageSharp.fluid;
          console.log(fluidImage);
          const alternativeText = image.alternativeText;

          return <ImageSlide key={index} fluidImage={fluidImage} alt={alternativeText} />;
        })}
      </div>
    </div>
  );
};

export default HomeHeroSlider;
