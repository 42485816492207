import React from 'react';

import { FormattedMessage } from 'react-intl';
import { PrimaryButton, SecondaryLink } from '../buttons';
import PropTypes from 'prop-types';

import './index.scss';
import HomeHeroSlider from '../home-hero-slider';

const Hero = ({ title, sliderImages }) => {
  return (
    <div id="hero">
      <HomeHeroSlider images={sliderImages} />
      <h1>{title}</h1>
      <div id="hero-buttons">
        <PrimaryButton to="/solutions/">
          <FormattedMessage id="our-solutions" />
        </PrimaryButton>
        <SecondaryLink to="/contact/">
          <FormattedMessage id="contact-us" />
        </SecondaryLink>
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Hero;
