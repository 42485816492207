import React from 'react';
import PropTypes from 'prop-types';

import { Languages } from '../../tools/languages';
import SimpleSection from '../simple-section';

import './index.scss';

const AboutUs = ({ language, embedUrl, description, label }) => (
  <div className="container">
    <SimpleSection className="container__header">
      <h2>{language === Languages.NL ? 'Over ons' : 'About us'}</h2>
    </SimpleSection>

    <div className="container__inner">
      <iframe
        style={{ aspectRatio: '16/9' }}
        width="100%"
        height="100%"
        src={embedUrl}
        title="Bavak - Securing Daily Life"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />

      <div className="container__inner__content">
        <p>{description}</p>
        <p className="container__inner__content__label">{label}</p>
      </div>
    </div>
  </div>
);

AboutUs.propTypes = {
  language: PropTypes.string,
  embedUrl: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
};

AboutUs.defaultProps = {
  language: Languages.EN,
};

export default AboutUs;
