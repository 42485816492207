import React from 'react';
import PropTypes from 'prop-types';

import { ColumnSection, Column } from '../column-section';
import { Languages } from '../../tools/languages';
import { PrimaryLink } from '../buttons';
import SimpleSection from '../simple-section';

const ServiceAndMaintenance = ({ language }) => {
  if (language === Languages.NL) {
    return (
      <>
        <SimpleSection>
          <h2>
            Service &amp; <br /> Onderhoud
          </h2>
          <p>
            Bavak levert merkonafhankelijk, zowel preventief als correctief onderhoud. Zo hebt u
            slechts één aanspreekpunt wat betreft Service &amp; Onderhoud aan uw
            beveiligingsinstallaties.
          </p>
          <PrimaryLink to="/support/">Ontdek meer</PrimaryLink>
        </SimpleSection>
        <ColumnSection indicator={true}>
          <Column isServiceColumn>
            <h3>Service</h3>
            <p>
              De jarenlange ervaring van Bavak heeft al onze medewerkers geleerd dat een project pas
              klaar is als de klant tevreden is. De behoefte opnemen en deze naar de volle
              tevredenheid uitvoeren. Dat is de service van Bavak.
            </p>
          </Column>
          <Column isServiceColumn>
            <h3>Onderhoud</h3>
            <p>
              Bavak levert merkonafhankelijk, zowel preventief als correctief onderhoud en diensten.
              Zo hebt u slechts één aanspreekpunt wat betreft onderhoud aan uw
              beveiligingsinstallaties.
            </p>
          </Column>
          <Column isServiceColumn>
            <h3>Training</h3>
            <p>
              Vooropgesteld is het belangrijk te weten dat geen enkele training standaard is. De
              gebruikers en onderhoud trainingen die de Bavak trainers geven zijn op verschillende
              niveaus en verschillende vakgebieden.
            </p>
          </Column>
        </ColumnSection>
      </>
    );
  }

  return (
    <>
      <SimpleSection>
        <h2>
          Service &amp; <br /> Maintenance
        </h2>
        <p>
          Bavak provides brand independent preventive- and corrective service &amp; maintenance,
          ensuring you only have one point of contact when it comes to reliability for your security
          installations.
        </p>
        <PrimaryLink to="/support/">Discover more</PrimaryLink>
      </SimpleSection>
      <ColumnSection indicator={true}>
        <Column isServiceColumn>
          <h3>Service</h3>
          <p>
            The years of experience at Bavak have taught all our employees that a project is
            finished only when the customer is satisfied. Taking in the needs and executing them to
            full satisfaction. That is the Bavak service.
          </p>
        </Column>
        <Column isServiceColumn>
          <h3>Maintenance</h3>
          <p>
            Bavak provides preventive as well as corrective maintenance and services, and does this
            brand independent. This way, you only have one single point of contact when it comes to
            maintenance of your security installations.
          </p>
        </Column>
        <Column isServiceColumn>
          <h3>Training</h3>
          <p>
            It is important to know that no training is standard. The user and maintenance training
            provided by the Bavak trainers are on different levels and in different fields.
          </p>
        </Column>
      </ColumnSection>
    </>
  );
};

ServiceAndMaintenance.propTypes = {
  language: PropTypes.string,
};

ServiceAndMaintenance.defaultProps = {
  language: Languages.EN,
};

export default ServiceAndMaintenance;
